exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industry-tsx": () => import("./../../../src/pages/industry.tsx" /* webpackChunkName: "component---src-pages-industry-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-staffing-tsx": () => import("./../../../src/pages/staffing.tsx" /* webpackChunkName: "component---src-pages-staffing-tsx" */),
  "component---src-pages-work-audit-trust-tsx": () => import("./../../../src/pages/work/audit_trust.tsx" /* webpackChunkName: "component---src-pages-work-audit-trust-tsx" */),
  "component---src-pages-work-bitfleq-tsx": () => import("./../../../src/pages/work/bitfleq.tsx" /* webpackChunkName: "component---src-pages-work-bitfleq-tsx" */),
  "component---src-pages-work-bnd-tsx": () => import("./../../../src/pages/work/bnd.tsx" /* webpackChunkName: "component---src-pages-work-bnd-tsx" */),
  "component---src-pages-work-logixa-tsx": () => import("./../../../src/pages/work/logixa.tsx" /* webpackChunkName: "component---src-pages-work-logixa-tsx" */),
  "component---src-pages-work-task-app-tsx": () => import("./../../../src/pages/work/task_app.tsx" /* webpackChunkName: "component---src-pages-work-task-app-tsx" */),
  "component---src-pages-work-ticket-app-tsx": () => import("./../../../src/pages/work/ticket_app.tsx" /* webpackChunkName: "component---src-pages-work-ticket-app-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-job-description-tsx": () => import("./../../../src/templates/jobDescription.tsx" /* webpackChunkName: "component---src-templates-job-description-tsx" */)
}

